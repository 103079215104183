import React, { useContext } from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { LOGIN_ERROR_TYPE } from 'shared/utils/authFlow/constants';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import TermsModalWrapper from './TermsModalWrapper';
import {
  TermsContainer,
  TermsTextContainer,
  TermsUpdateScreenTextWrapper,
  TermsImageWrapper,
  TermsImage,
  TermsFooterWrapper,
  GeneralSignImModalTopSpace,
  SingInModalContainer,
  ModalButtonWrapper,
  ModalFooter,
} from '../styledComponents';
import ImageBW from '../../ImageBoundsWrapper';
import Styles from '../styles';
import Button from '../../Button/Button';
import ThemeContext from 'shared/context/ThemeContext';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

export const DefaultTermsModal = props => {
  const {
    isVisible,
    closeModal,
    closeRetryModal,
    terms,
    setTerms,
    onSubmit,
    onFacebookActivate,
    onGoogleActivate,
    error,
    socialError,
    socialErrorType,
    statusMessage,
    clientText,
    termsAndConditionsUrl,
    privacyPolicyUrl,
  } = props;
  const { TermsModal } = useContext(ThemeContext);
  return (
    <>
      <TermsModalWrapper
        isVisible={isVisible}
        closeModal={closeModal}
        modalStyle={TermsModal.TermsAndPrivacyModal}
        title={getText(TEXT_KEY.TERMS_AND_CONDITIONS_TITLE)}
        zIndex={101}
      >
        <TermsContainer className="terms-container">
          <TermsTextContainer>
            <>
              <TermsUpdateScreenTextWrapper>
                {getText(TEXT_KEY.CONFIRM_TERMS_AGREE)}
                {clientText} {getText(TEXT_KEY.TERMS_AND_CONDITIONS)}.{getText(TEXT_KEY.MARKETING_CONSENT)}
                {clientText}
                {getText(TEXT_KEY.PRIVACY_POLICY_AGREE)}
                {clientText}
              </TermsUpdateScreenTextWrapper>

              <div>
                <TermsImageWrapper>
                  <TermsImage
                    src={assetSource(ASSET_KEY.TERMS_CONDITIONS)}
                    alt=""
                    resizeMode={'contain'}
                    onClick={() => window.open(termsAndConditionsUrl)}
                  />
                </TermsImageWrapper>

                <TermsImageWrapper>
                  <TermsImage
                    src={assetSource(ASSET_KEY.PRIVACY_POLICY)}
                    alt=""
                    resizeMode={'contain'}
                    onClick={() => window.open(privacyPolicyUrl)}
                  />
                </TermsImageWrapper>
              </div>
            </>
          </TermsTextContainer>
        </TermsContainer>
        <TermsFooterWrapper className="terms-footer">
          <div className="accept-terms-container">
            <ImageBW
              source={terms ? assetSource(ASSET_KEY.CHECKBOX) : assetSource(ASSET_KEY.CHECKBOX_DESELECTED)}
              ibw={require(asset`checkbox.ibw`)}
              style={{
                objectFit: 'contain',
              }}
              className="checkbox-image"
              onClick={setTerms}
            />
            <p qa-id="checkboxTermsAndConditions" className="accept-terms-text" onClick={setTerms}>
              {getText(TEXT_KEY.AGREE_TERMS)}
            </p>
          </div>
          <div qa-id="continueBtn" className="continue-button-container">
            <Button
              imageSource={
                terms ? assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY) : assetSource(ASSET_KEY.ROUNDED_RECT_DISABLED)
              }
              disabled={!terms}
              imageStyle={{ width: '195px', height: '68px' }}
              containerStyle={{ cursor: 'pointer' }}
              onClick={terms ? onSubmit : () => {}}
              label={getText(TEXT_KEY.CONTINUE_BUTTON)}
              textStroke={BUTTON_STROKES.PRIMARY}
            />
          </div>
        </TermsFooterWrapper>
      </TermsModalWrapper>

      <TermsModalWrapper
        isVisible={!!error && !socialError}
        closeModal={closeModal}
        title={getText(TEXT_KEY.GENERIC_LOGIN_ERROR_TITLE)}
        zIndex={101}
        modalStyle={Styles.modalStyle}
        modalContainerStyle={Styles.modalContainerStyle}
        titleStyle={Styles.titleStyle}
      >
        <GeneralSignImModalTopSpace />
        <SingInModalContainer>
          <p className="verified-text">{getText(TEXT_KEY.GENERIC_LOGIN_ERROR_MESSAGE)}</p>
          <ModalButtonWrapper>
            <Button
              imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
              imageStyle={{ width: '195px', height: '68px' }}
              containerStyle={{ cursor: 'pointer' }}
              label={getText(TEXT_KEY.CONTACT_BUTTON)}
              onClick={() => {
                closeModal();
                window.open(ENVIRONMENT_VARIABLES.ZENDESK_URL, '_blank');
              }}
              textStyle={TermsModal.ContactUsButtonTextStyle}
              textStroke={BUTTON_STROKES.PRIMARY}
            />
          </ModalButtonWrapper>
          <ModalFooter />
        </SingInModalContainer>
      </TermsModalWrapper>
      <TermsModalWrapper
        isVisible={!!socialError && !error}
        closeModal={closeRetryModal}
        title={
          socialErrorType === LOGIN_ERROR_TYPE.FACEBOOK ? getText(TEXT_KEY.SHARE_EMAIL) : getText(TEXT_KEY.TRY_AGAIN)
        }
        zIndex={101}
        modalStyle={Styles.socialModalStyle}
        modalContainerStyle={Styles.SocialModalContainerStyle}
        titleStyle={Styles.titleStyle}
        modalMainBodyStyle={Styles.modalMainBodyStyle}
        disableClose
      >
        <SingInModalContainer>
          <p className="error-text">{typeof statusMessage === 'string' && statusMessage}</p>
          <ModalButtonWrapper>
            <Button
              imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
              imageStyle={{ width: '195px', height: '68px' }}
              containerStyle={{ cursor: 'pointer' }}
              label={getText(TEXT_KEY.RETRY_BUTTON)}
              onClick={() => {
                closeRetryModal();
                onGoogleActivate && socialErrorType === LOGIN_ERROR_TYPE.GOOGLE && onGoogleActivate();
                onFacebookActivate && socialErrorType === LOGIN_ERROR_TYPE.FACEBOOK && onFacebookActivate();
              }}
              textStyle={TermsModal.SocialErrorText}
              textStroke={BUTTON_STROKES.PRIMARY}
            />
          </ModalButtonWrapper>
          <ModalFooter />
        </SingInModalContainer>
      </TermsModalWrapper>
    </>
  );
};

