import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Modal from '../../../components/JSModal';
import { tutorialOpacity } from 'shared/utils/tutorial';
import { generateTutorialInfoScreen } from 'shared/screens/Missions/utils/generateMissionRendering';
import { sendMissionsEvent } from 'shared/screens/Missions/analytics/missionsLogger';
import { EVENT_TYPES } from 'shared/lib/analytics/constants';
import { pwaStyleAdapter, isNotStandaloneMobilePWA } from '../../../utils/mobileSupported';

const MissionHelp = ({ activeTutorial, missionsAssets }) => {
  const [openModal, setOpenModal] = useState(false);
  const [tutorialScreen, setTutorialScreen] = useState(null);

  const handleOpenModal = () => {
    sendMissionsEvent(EVENT_TYPES.MISSIONS_OPEN_INFO_SCREEN);
    setTutorialScreen(missionsAssets.TUTORIAL_INFO_SCREEN_STEP_1);
    setOpenModal(true);
  };

  const findIndexScreen = () => generateTutorialInfoScreen(missionsAssets).findIndex(x => x === tutorialScreen);
  const handleBackScreen = () => setTutorialScreen(generateTutorialInfoScreen(missionsAssets)[findIndexScreen() - 1]);
  const handleFowardScreen = () => setTutorialScreen(generateTutorialInfoScreen(missionsAssets)[findIndexScreen() + 1]);

  return (
    <Wrapper disabled={activeTutorial}>
      <HelpContainer>
        <div onClick={() => handleOpenModal()}>
          <HelpImage src={missionsAssets.TUTORIAL_INFO_BUTTON} />
        </div>
      </HelpContainer>
      <Modal
        style={modalStyle}
        modalBodyStyle={modalBodyStyle}
        isVisible={openModal}
        hideDefaultBackground
        hideCloseButton
      >
        <ModalContainer>
          <ImageBackground src={tutorialScreen} />
          <CloseButton onClick={() => setOpenModal(!openModal)}>
            <Image src={missionsAssets.TUTORIAL_INFO_SCREEN_CLOSE_BTN} />
          </CloseButton>
          <BackButton indexScreen={findIndexScreen()} onClick={() => handleBackScreen()}>
            <Image src={missionsAssets.TUTORIAL_INFO_SCREEN_ARROW} />
          </BackButton>
          <ForwardButton indexScreen={findIndexScreen()} onClick={() => handleFowardScreen()}>
            <Image src={missionsAssets.TUTORIAL_INFO_SCREEN_ARROW} />
          </ForwardButton>
        </ModalContainer>
      </Modal>
    </Wrapper>
  );
};

export default MissionHelp;

const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;

const modalStyle = {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '6vh 16vw 0',
  zIndex: '10',
};

const modalBodyStyle = {
  padding: '0',
  marginTop: windowWidth < 1400 && windowHeight < 650 ? '50px' : 'auto',
};

const Wrapper = styled.div`
  z-index: 5;
  opacity: ${({ disabled }) => tutorialOpacity(disabled)};
`;

const HelpContainerSmall = css`
  bottom: 2rem;
  left: 2rem;
`;
const HelpContainerRegular = css`
  top: ${pwaStyleAdapter(5.3, 'rem')};
`;

const HelpContainer = styled.div`
  position: absolute;
  flex: 1;
  z-index: 5;
  left: 1rem;
  ${isNotStandaloneMobilePWA ? HelpContainerSmall : HelpContainerRegular}
`;

const HelpImage = styled.img`
  cursor: pointer;
  height: 3.6rem;
  width: 3.6rem;
`;

const ModalContainer = styled.div`
  width: 100%;
`;

const Image = styled.img`
  position: relative;
  width: 100%;
`;

const ImageBackgroundSmallScreen = css`
  height: 55vh;
  object-fit: contain;
`;

const ImageBackground = styled(Image)`
  ${isNotStandaloneMobilePWA && ImageBackgroundSmallScreen}
`;

const CloseButtonSmall = css`
  top: -7%;
  right: 17vh;
`;

const CloseButton = styled.div`
  position: absolute;
  width: 13vh;
  top: -4.5%;
  right: -4vh;
  ${isNotStandaloneMobilePWA && CloseButtonSmall}
`;

const BackButtonSmall = css`
  top: calc(50% - 6.5vh);
  left: 17vh;
`;

const BackButton = styled.div`
  position: absolute;
  width: 13vh;
  top: 43%;
  left: -6.5vh;
  display: ${({ indexScreen }) => (indexScreen === 0 ? 'none' : 'block')};
  ${isNotStandaloneMobilePWA && BackButtonSmall}
`;

const ForwardButtonSmall = css`
  top: calc(50% - 6.5vh);
  right: 17vh;
`;

const ForwardButton = styled.div`
  position: absolute;
  width: 13vh;
  top: 43%;
  right: -6.5vh;
  transform: rotateY(180deg);
  display: ${({ indexScreen }) => (indexScreen === 2 ? 'none' : 'block')};
  ${isNotStandaloneMobilePWA && ForwardButtonSmall}
`;

