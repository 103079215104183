import React from 'react';
import styled from 'styled-components';
import Modal from '../../../../components/JSModal';
import EventsInfoSliders from './Slides';
import useEventsTutorial from 'shared/screens/Events/hooks/useEventsTutorial';
import generateEventsInfoScreenRender from 'shared/screens/Events/utils/generateEventsInfoScreenRender';
import { isMobile, isTablet } from 'react-device-detect';
import { getContentModalWrapperDimension, getRightCloseButton } from '../../utils';
import { isStandalonePWA, pwaStyleAdapter } from '../../../../utils/mobileSupported';

export default function EventsInfo({ eventsAssets, eventsTexts }) {
  const { showInfoScreen, lastSlide, openInfoScreen, dismissInfoScreen } = useEventsTutorial();
  const infloSliderData = generateEventsInfoScreenRender(eventsTexts);

  return (
    <React.Fragment>
      <InfoContainer>
        <InfoImage onClick={openInfoScreen} src={eventsAssets.EVENTS_TUTORIAL_INFO_ICON} />
      </InfoContainer>
      <Modal
        style={modalStyle}
        modalBodyStyle={modalBodyStyle}
        isVisible={showInfoScreen}
        hideDefaultBackground
        hideCloseButton
      >
        <ModalContainer>
          <ModalContentWrapper>
            <InfoModalBackgroundImage src={eventsAssets.EVENTS_TUTORIAL_INFO_MODAL_BG} />
            <SpaceContainer />
            <SliderContentWrapper>
              <SliderTopSpace />
              <EventsInfoSliders lastSlide={lastSlide} slides={infloSliderData} eventsAssets={eventsAssets} />
            </SliderContentWrapper>
            <CloseButton onClick={dismissInfoScreen}>
              <Image src={eventsAssets.EVENTS_TUTORIAL_CLOSE_ICON} />
            </CloseButton>
          </ModalContentWrapper>
        </ModalContainer>
      </Modal>
    </React.Fragment>
  );
}

const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;

const modalStyle = {
  width: '100%',
  height: '100%',
  display: 'flex',
  padding: '6vh 16vw 0',
  zIndex: '99999',
};

const modalBodyStyle = {
  padding: '0',
};

const ModalContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const InfoContainer = styled.div`
  position: absolute;
  flex: 1;
  z-index: 5;
  top: ${isMobile && !isTablet && !isStandalonePWA ? '84%' : pwaStyleAdapter(5.3, 'rem')};
  left: ${isMobile && !isTablet && !isStandalonePWA ? '2.1rem' : '1rem'};
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const SliderTopSpace = styled.div`
  height: ${windowWidth < 1400 ? 10 : 10}%;
`;

const InfoImage = styled.img`
  cursor: pointer;
  height: 3.6rem;
  width: 3.6rem;
`;

const InfoModalBackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
  z-index: 0;
`;

const CloseButton = styled.div`
  position: absolute;
  width: 80px;
  top: ${!isStandalonePWA && !isTablet && isMobile ? 13 : isTablet ? 15 : windowWidth < 1400 ? (windowHeight > 800 ? 13 : 15) : windowHeight < 880 ? 14 : 15}vh;
  right: ${getRightCloseButton()};
`;

const Image = styled.img`
  position: relative;
  width: 100%;
`;

const ModalContentWrapper = styled.div`
  width: ${getContentModalWrapperDimension('width')};
  height: ${getContentModalWrapperDimension('height')}%;
  left: ${getContentModalWrapperDimension('left')};
  bottom: 0px;
  position: absolute;
  z-index: 1;
  border-radius: 50px;
  display: flex;
`;

const SpaceContainer = styled.div`
  height: 100%;
  flex: 3;
`;

const SliderContentWrapper = styled(SpaceContainer)`
  display: flex;
  flex: 3;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;
  margin-right: 18px;
`;

