import styled, { css } from 'styled-components';
import { Image } from 'react-bootstrap';
import theme from 'shared/assets/style/theme';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { isNotStandaloneMobilePWA } from '../../utils/mobileSupported';

const SmallSplashScreen = css`
  background-position: center 25%;
  ${({ theme }) => theme}
`;

export const SplashScreen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${assetSource(ASSET_KEY.SPLASH_BG)});
  ${isNotStandaloneMobilePWA && SmallSplashScreen}
`;

export const ErrorText = styled.p`
  text-align: center;
  margin-top: 10px;
  color: red;
`;

export const StartScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const StartScreenWrapperMiddleDesign = styled(StartScreenWrapper)`
  justify-content: center;
  flex: 1;
  ${({ theme }) => theme}
`;

export const LogoImage = styled(Image)`
  ${({ applyTheme }) => applyTheme}
  width: 600px;
`;

export const MainContentContainer = styled.div`
  ${({ theme, guestButton }) => ({ ...theme, ...(guestButton && { marginTop: (theme.marginTop || 0) - 20 }) })}
  align-items: center;
  justify-content: center;
  flex-direction: column;
  bottom: 15%;
  position: unset;
  width: 900px;
  z-index: 100;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 50rem;
  ${({ theme }) => theme}
`;

export const MainContentHeader = styled.p`
  font-size: 20px;
  margin: 0px;
  padding-top: 5px;
  font-weight: 600;
`;

export const SignInButtonWrapper = styled.div`
  justify-content: center !important;
  padding: 5px 0px;
`;

export const GuestLoginButtonWrapper = styled(SignInButtonWrapper)`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  pointer-events: 'auto';
  margin-bottom: 20px;
`;

export const SignInButtonImage = styled.img`
  width: 30%;
  height: 35%;
`;

export const BottomBar = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: fixed;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background-image: ${({ bgImage }) => `url(${bgImage})`};
  background-size: cover;
`;

export const BottomBarFirstLine = styled.p`
  ${({ theme }) => theme}
  z-index: 1;
  margin: 0px;
  padding-top: 30px;
  font-size: 20px;
`;

export const BottomBarSecondContainer = styled.div`
  z-index: 1;
  margin-bottom: 15px;
`;

export const BottomBarSecondLine = styled.p`
  margin: 0;
  text-decoration: underline;
  padding-top: 5px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: ${theme.palette.common[1]};
`;

export const BottomBarThirdLine = styled.p`
  margin: 0;
  cursor: pointer;
  color: ${theme.palette.common[1]};
`;

export const OneLineActionsContainer = styled.div`
  display: flex;
  padding: 15px;
  width: 500px;
  justify-content: space-around;
  ${({ theme }) => theme}
`;

export const FooterText = styled.p`
  max-width: 1100px;
  text-align: center;
  font-size: 0.7em;
  ${({ theme }) => theme}
`;

