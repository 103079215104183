import { EVENT_TYPES } from '../../../lib/analytics/constants';
import Logger from '../../../lib/analytics/logger';
import ERROR_CODE from '../../errorCodes';
import { FLAG_VALUES } from '../constants';
var remoteConfigOnSuccess = function remoteConfigOnSuccess(response, handlerCallback) {
  var featureConfigs = {};
  FLAG_VALUES.forEach(function (feature) {
    try {
      var _response$remoteConfi;
      var featureEntry = (_response$remoteConfi = response.remoteConfig) === null || _response$remoteConfi === void 0 || (_response$remoteConfi = _response$remoteConfi.params) === null || _response$remoteConfi === void 0 ? void 0 : _response$remoteConfi.find(function (entry) {
        return entry.key === feature;
      });
      var featureData = featureEntry ? featureEntry.value : 0;
      var parsedData = featureData && JSON.parse(featureData);
      if (parsedData) featureConfigs[feature] = parsedData;
    } catch (e) {
      Logger.sendEvent(EVENT_TYPES.FRONTEND_ERROR, {
        error: JSON.stringify(e),
        code: ERROR_CODE.DIVE_JSON_PARSE_FAIL
      });
    }
  });
  handlerCallback(featureConfigs);
};
var remoteConfigOnFail = function remoteConfigOnFail(error) {
  Logger.sendEvent(EVENT_TYPES.FRONTEND_ERROR, {
    error: JSON.stringify(error),
    code: ERROR_CODE.DIVE_REMOTE_CONFIG_FAIL
  });
};
var diveRemoteConfig = function diveRemoteConfig(playerId, handlerCallback, diveSDK) {
  return diveSDK.getInstance().GetRemoteConfig(playerId, function (response) {
    return remoteConfigOnSuccess(response, handlerCallback);
  }, function (error) {
    return remoteConfigOnFail(error);
  });
};
export default diveRemoteConfig;