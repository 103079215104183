// import { isMobile, isTablet } from 'react-device-detect';
import { PLATFORM, SYSTEM_OS } from '../constants';
export default {
  getPlatform: function getPlatform() {
    // return isMobile || isTablet ? PLATFORM.MOBILE_WEB : PLATFORM.WEB;TODO
    return PLATFORM.WEB;
  },
  getSystemOs: function getSystemOs() {
    var userAgent = window.navigator.userAgent;
    if (userAgent.includes('Windows')) return SYSTEM_OS.WINDOWS;
    if (userAgent.includes('Macintosh') || userAgent.includes('Mac OS X')) return SYSTEM_OS.MACOS;
    if (userAgent.includes('Linux')) return SYSTEM_OS.LINUX;
    if (userAgent.includes('Android')) return SYSTEM_OS.ANDROID;
    if (userAgent.includes('iPhone') || userAgent.includes('iPad')) return SYSTEM_OS.IOS;
    return 'Unknown';
  },
  isWebOrMobileWeb: function isWebOrMobileWeb() {
    return this.getPlatform() === PLATFORM.WEB || this.getPlatform() === PLATFORM.MOBILE_WEB;
  }
};