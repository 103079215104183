import React, { useEffect, useReducer } from 'react';
import { Spinner } from 'react-bootstrap';
import { SplashScreen, StartScreenWrapperMiddleDesign, ButtonContainer } from './styledComponents';
import Styles from './styles';
import RouteParamConstants from 'shared/utils/routeParamConstants';
import SStart from 'shared/screens/Start';
import audio from 'shared/utils/audio';
import Signup from '../Signup';
import ResetPassword from '../ResetPassword';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import TermsModal from '../../components/TermsModal';
import SocialLoginInterface from 'shared/api/SocialLoginInterface';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import Button from '../../components/Button/Button';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import TermsModalWrapper from '../../components/TermsModal/TermsModalComponents/TermsModalWrapper';
import generateRender from 'shared/utils/generateRender';
import useThemeContext from 'shared/context/hooks/useThemeContext';
import { pwaStyleAdapter } from '../../utils/mobileSupported';

const MiddleLayout = props => {
  const { Start: StartThemes } = useThemeContext();
  const [state, setState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      oauthLoading: false,
      statusMessage: null,
      emailPermissionAccepted: null,
      socialError: null,
      socialErrorType: null,
      playAsGuest: ENVIRONMENT_VARIABLES.PLAY_AS_GUEST,
      error: null,
    }
  );

  useEffect(() => {
    if (props.navigation.getParam(RouteParamConstants.PARAM_ACTION)) {
      _handleActionRedirect();
    }
    if (props.navigation.getParam(RouteParamConstants.PARAM_TRACKING_URL_AFF_ID)) {
      let aff_Id = props.navigation.getParam(RouteParamConstants.PARAM_TRACKING_URL_AFF_ID);
      Array.isArray(aff_Id) ? props.setUserAffId(aff_Id[0]) : props.setUserAffId(aff_Id);
    }
  }, []);

  const _handleActionRedirect = () => {
    window.location.assign(props.navigation.getParam(RouteParamConstants.PARAM_ACTION));
  };

  useEffect(() => {
    props.googleToken &&
      SocialLoginInterface.googleLoginHandler({
        setSuccessState: setSuccessState,
        setErrorState: setErrorState,
        setCanceledState: setCanceledState,
      });
  }, [props.googleToken]);

  const setSuccessState = () => {
    setState({
      oauthLoading: true,
      statusMessage: null,
      socialError: null,
      socialErrorType: null,
      error: null,
    });
  };

  const setErrorState = () => {
    setState({
      oauthLoading: false,
      statusMessage: null,
      socialError: null,
      socialErrorType: null,
      error: true,
    });
  };

  const setCanceledState = ({ statusMessage, emailPermissionAccepted, socialErrorType }) => {
    setState({
      oauthLoading: false,
      statusMessage,
      emailPermissionAccepted,
      socialError: true,
      socialErrorType,
      error: null,
    });
  };

  const closeModal = () => {
    audio.onClick();
    setState({
      statusMessage: null,
      socialError: false,
      socialErrorType: null,
      error: null,
    });
  };

  return (
    <SStart
      navigation={props.navigation}
      mountIntent={{
        facebook: () =>
          SocialLoginInterface.facebookLoginHandler({
            FB,
            emailPermissionAccepted: state.emailPermissionAccepted,
            setSuccessState: setSuccessState,
            setErrorState: setErrorState,
            setCanceledState: setCanceledState,
          }),
      }}
      render={args => {
        const {
          onGuestActivate,
          onAccountActivate,
          onFacebookActivate,
          isVisible,
          terms,
          isLoading,
          closeModal: argsCloseModal,
          onSubmit,
          setTerms,
          termsAndConditionsUrl,
          privacyPolicyUrl,
          showSignUpForm,
          signupHandler,
          recoverPasswordResponse,
          hasFacebook,
        } = args;

        const buttonData = [
          {
            imageSource: assetSource(ASSET_KEY.ROUNDEDRECT_PRIMARY_WIDE),
            onClick: onAccountActivate,
            label: getText(TEXT_KEY.LOGIN),
            textStroke: BUTTON_STROKES.PRIMARY,
          },
          {
            imageSource: assetSource(ASSET_KEY.ROUNDEDRECT_TERTIARY_WIDE),
            onClick: signupHandler,
            label: getText(TEXT_KEY.SIGN_UP),
            textStroke: SignUpTextStroke,
          },
          hasFacebook && {
            imageSource: assetSource(ASSET_KEY.FACEBOOK_BUTTON),
            onClick: onFacebookActivate,
            visible: true,
          },
          state.playAsGuest === 'true' && {
            imageSource: assetSource(ASSET_KEY.ROUNDEDRECT_DISABLED_WIDE),
            onClick: onGuestActivate,
            label: getText(TEXT_KEY.PLAY_AS_GUEST),
            textStroke: BUTTON_STROKES.DISABLED,
          },
        ].filter(Boolean);

        return (
          <SplashScreen theme={StartThemes.SplashScreenBackground} className="SplashScreen">
            <Signup
              isModal
              isVisible={showSignUpForm}
              title={getText(TEXT_KEY.SIGNUP_TITLE)}
              closeModal={argsCloseModal}
              closeRetryModal={closeModal}
              zIndex={101}
              modalStyle={Styles.modalStyle}
              modalContainerStyle={Styles.modalContainerStyle}
              titleStyle={Styles.titleStyle}
              termsAndConditionsUrl={termsAndConditionsUrl}
              privacyPolicyUrl={privacyPolicyUrl}
              navigation={props.navigation}
            />
            <ResetPassword
              isVisible={recoverPasswordResponse.visible}
              closeModal={argsCloseModal}
              zIndex={101}
              navigation={props.navigation}
              invalid={recoverPasswordResponse.invalid}
              errorMessage={recoverPasswordResponse.message}
              recoverId={recoverPasswordResponse.id}
              recoverToken={recoverPasswordResponse.token}
              getTextFunc={getText}
              textKeys={TEXT_KEY}
              TermsModalWrapper={TermsModalWrapper}
            />
            <TermsModal
              isVisible={isVisible}
              closeModal={argsCloseModal}
              closeRetryModal={closeModal}
              terms={terms}
              setTerms={setTerms}
              onSubmit={onSubmit}
              onFacebookActivate={onFacebookActivate}
              error={state.error}
              socialError={state.socialError}
              socialErrorType={state.socialErrorType}
              statusMessage={state.statusMessage}
              clientText={getText(TEXT_KEY.CLIENT_TITLE)}
              termsAndConditionsUrl={termsAndConditionsUrl}
              privacyPolicyUrl={privacyPolicyUrl}
            />
            <StartScreenWrapperMiddleDesign theme={StartThemes.MiddleSectionWrapper}>
              <ButtonContainer theme={StartThemes.MiddleLayoutButtonContainer}>
                {buttonData.map((button, index) => (
                  <Button
                    key={index}
                    imageSource={button.imageSource}
                    onClick={button.onClick}
                    containerStyle={pwaStyleAdapter(Styles.middleDesignButtonStyle, null, 1.2)}
                    imageStyle={pwaStyleAdapter(Styles.middleDesignButtonStyle, null, 1.2)}
                    objectFit="contain"
                    label={button.label}
                    textStroke={button.textStroke}
                    textStyle={{ fontSize: pwaStyleAdapter(30, 'px', 1.2) }}
                  />
                ))}
              </ButtonContainer>
            </StartScreenWrapperMiddleDesign>
            {isLoading && !state.oauthLoading && (
              <Spinner style={{ position: 'absolute' }} animation="grow" className="custom-spinner" />
            )}
            {state.oauthLoading && !isLoading && (
              <Spinner style={{ position: 'absolute' }} animation="grow" className="custom-spinner" />
            )}
            {props?.children && props?.children}
          </SplashScreen>
        );
      }}
    />
  );
};

const SignUpTextStroke = generateRender({
  default: BUTTON_STROKES.TERTIARY,
  hri: BUTTON_STROKES.SECONDARY,
});

MiddleLayout.path = '';

export default MiddleLayout;

